import './styles.css';
import React from 'react';

function Footer() {
  
  return (
    <div className='status-div footer'>
      2 Truths And A Lie | © 2023
    </div>
  );
}

export default Footer;